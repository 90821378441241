
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
    getComponent,
    getConfigEnv,
    sleep,
} from '@/utils/helpers';
import draggable from 'vuedraggable';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import scheduleModule from '@/store/modules/scheduleModule';
import Multiselect from 'vue-multiselect';
import { getNameByEmail } from '@/utils/users';
import { DateTime } from 'luxon';
import {
  SCHEDULE_BUCKET_REGION,
  SCHEDULE_BUCKET_NAME,
  IDENTITY_POOL_ID,
} from '@/lib/constants';

import workspaceModule from '@/store/modules/workspaceModule';
import AWS from 'aws-sdk';
import tasqsListModule from '@/store/modules/tasqsListModule';
import metaDataModule from '@/store/modules/metaDataModule';

@Component({
    components: {
        AppLayout: () => getComponent('common/AppLayout'),
        RigSchedule: () => getComponent('schedule/RigSchedule'),
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
      TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    Dropdown: () => getComponent('common/Dropdown'),
    Multiselect,
    draggable,
    },
})

export default class ComponentDetails extends Vue {
  refreshFilesKey = 0

  filesKeyCount = 1

  noFilesKeyCount = 1

  storedZipFile;

  showDeleteFileOption = ''

  componentStatus: any = [{
 text: 'In Progress', value: 'In Progress', color: '#059bff', disabled: false,
}]

  editingDropdownType = ''

  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  editGroupPopupInnerShowing = false

  componentDateRange: any[] = []

  dropdownLocation: any[] = []

  waitingOnAction: any = {}

  statusOptions: any[] = [{
    text: 'New', value: 'New', color: '#aaaaaa', disabled: false,
  }, {
    text: 'In Progress', value: 'In Progress', color: '#059bff', disabled: false,
  }, {
    text: 'Waiting On', value: 'Waiting On', color: '#dba848', disabled: false,
  }, {
    text: 'Scheduled', value: 'Scheduled', color: '#A775FF', disabled: false,
  }, {
    text: 'Done', value: 'Done', color: '#34a865', disabled: false,
  }]

  addJobIsLoading = false

  dataLoading = false

  showDeleteComponentOption = false

  editingPreviousValue = null

  selectedComponentDetailsSummarySections: any[] = []

  userDropdownModel = []

  editingIndex = null

  showModal = false;

  itemTitleModel = ''

  windowWidth = window.innerWidth;

  startDateModal: any = ''

  dueDateModal: any = ''

  newChecklistItemInputModal = ''

  descriptionModel = ''

  isHoveringIndex = -1

  actionErrors: any = [];

  testingMore = [
    { value: 'One', checked: false },
    { value: 'Two', checked: false },
    { value: 'Three', checked: false },
    { value: 'Four', checked: false },
    { value: 'Five', checked: false },
    { value: 'Six', checked: false },
    { value: 'Seven', checked: false },
    { value: 'Eight', checked: false },
  ]

  activeMoveTasqID: any = null;

  ignoreStartDatePicker = false

  ignoreEndDatePicker = false

  get isMobile() {
    return this.windowWidth < 768;
  }

  closeDialog() {
    this.showModal = false;
  }

  get waitingOnOptions() {
    return metaDataModule.waitingOnList;
  }

  get kanbanTasq() {
    return tasqsListModule.kanbanSelectedTasq;
  }

  openPopupModal(wellName) {
    const tasq = tasqsListModule.tasqList.find((t) => t.wellName === wellName);
    if (tasq && tasq.id) {
      tasqsListModule.setActiveTasq(tasq.id || '');
      tasqsListModule.setKanbanSelectedTasq(tasq?.id || '');
      this.showModal = true;
    } else {
      tasqsListModule.setKanbanSelectedTasq(wellName);
      assetsModule.setActiveProducingTasq(wellName);
      tasqsListModule.setActiveTasq('');
      this.showModal = true;
    }
  }

 openDialog() {
    this.sleep(300).then(() => {
      this.$dialog.show('actionModal');
    });
  }

  validateDialog() {
    if (!this.waitingOnAction.date) {
      this.actionErrors.push('date');
    }
    if (!this.waitingOnAction.reason) {
      this.actionErrors.push('reason');
    }
  }

  prepareAction(scheduleJobId) {
    this.waitingOnAction = {
      type: '',
      title: '',
      confirmText: '',
      reason: '',
      person: '',
      comment: '',
      date: '',
      id: scheduleJobId,
      well: '',
      jobType: '',
      frequency: 'Today',
      delayedDays: 0,
    };
    this.waitingOnAction.type = 'wait';
    this.waitingOnAction.title = 'Please select status for Schedule job:';
    this.waitingOnAction.confirmText = 'Update';
    this.$dialog.show('actionModal');
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async onActionDialogClosed(response) {
    this.actionErrors = [];
    if (response.isOk) {
    this.validateDialog();
    if (this.actionErrors.length) {
      this.openDialog();
      return;
    }
    await scheduleModule.updateItemStatus({
      // @ts-ignore
      item_id: this.waitingOnAction.id,
      item_status: 'Waiting On',
      payload: {
        Status: true,
        WaitStartDate: new Date().toLocaleDateString(),
        UntilDate: this.waitingOnAction.date,
        WaitUntilReason: this.waitingOnAction.reason,
      },
    });
    }
    this.waitingOnAction = {};
  }

  async didLoseFocusOnEditTitleRowText() {
    for (let b = 0; b < this.activeColumns.length; b++) {
      if (this.activeColumns[b].ColumnType === 'TITLE') {
        this.$emit('set-saving-details-banner-id', 'Updating title');
        // eslint-disable-next-line no-await-in-loop
        await this.updateComponentTextValue(this.activeColumns[b], this.itemTitleModel, 'VALUE');
      }
    }
  }

  async didLoseFocusOnEditDescriptionRowText(selectedComponentDetailsSection) {
    for (let b = 0; b < this.activeColumns.length; b++) {
      if (this.activeColumns[b].CustomID === selectedComponentDetailsSection.CustomID) {
        this.$emit('set-saving-details-banner-id', 'Updating description');
        // eslint-disable-next-line no-await-in-loop
        await this.updateComponentTextValue(this.activeColumns[b], this.descriptionModel, 'VALUE');
      }
    }
  }

  didHoverChecklist(index) {
    this.isHoveringIndex = index;
  }

  didLeaveHoverChecklist() {
    this.isHoveringIndex = -1;
  }

  async didEndChecklistDrag(sectionDetails) {
    for (let b = 0; b < this.activeColumns.length; b++) {
      if (this.activeColumns[b].CustomID === sectionDetails.CustomID) {
        this.$emit('set-saving-details-banner-id', 'Reordering checklist');
        const newResponse = JSON.parse(JSON.stringify(sectionDetails.Response));
        // eslint-disable-next-line no-await-in-loop
        await this.updateComponentTextValue(this.activeColumns[b], newResponse, 'VALUE');
      }
    }
  }

  checkMoveChecklist(event) {
    const { draggedContext } = (event);
    this.activeMoveTasqID = (draggedContext.element.id);
  }

  async deleteChecklistItem(checklistIndex) {
    for (let b = 0; b < this.activeColumns.length; b++) {
      if (this.activeColumns[b].ColumnType === 'CHECKLIST') {
        this.$emit('set-saving-details-banner-id', 'Removing from checklist');

        const newResponse = JSON.parse(JSON.stringify(this.activeColumns[b].Response));
        if (checklistIndex > -1) {
          newResponse.splice(checklistIndex, 1);
        }
        // eslint-disable-next-line no-await-in-loop
        await this.updateComponentTextValue(this.activeColumns[b], newResponse, 'VALUE');
        this.$emit('update-component-details');
      }
    }
  }

  async addJobDidEnterChecklistItem(e) {
    if ((e.key === 'Enter' || e.keyCode === 13)) {
      if (this.newChecklistItemInputModal === '') {
        return;
      }

      for (let b = 0; b < this.activeColumns.length; b++) {
        if (this.activeColumns[b].ColumnType === 'CHECKLIST') {
          this.$emit('set-saving-details-banner-id', 'Adding to checklist');

          const newResponse = JSON.parse(JSON.stringify(this.activeColumns[b].Response));
          newResponse.push({
            checked: false,
            value: this.newChecklistItemInputModal,
          });
          // eslint-disable-next-line no-await-in-loop
          await this.updateComponentTextValue(this.activeColumns[b], newResponse, 'VALUE');
          this.newChecklistItemInputModal = '';
          this.$emit('update-component-details');
        }
      }
    }
  }

  didSelectDropdown(e, type, index = null) {
    this.editingDropdownType = type;
    this.editingIndex = index;
      if (type === 'User') {
        if (this.editingIndex !== null) {
          // @ts-ignore
          this.editingPreviousValue = this.selectedComponentDetailsSummarySections[this.editingIndex].Response;
        }

        const rect = e.srcElement.getBoundingClientRect();

        this.dropdownLocation = [rect.x - 140, rect.y + 38];
      } else if (type === 'Core Status') {
        this.editingPreviousValue = this.componentStatus[0].value;
      // @ts-ignore
        for (let x = 0; x < this.activeColumns.length; x++) {
          if (this.activeColumns[x].ColumnType === 'STATUS' && this.activeColumns[x].CoreField) {
            // @ts-ignore
            this.editingIndex = x;
          }
        }
        const rect = e.srcElement.getBoundingClientRect();
        this.dropdownLocation = [rect.x - 0, rect.y + 38];
      } else {
        const rect = e.srcElement.getBoundingClientRect();

        this.dropdownLocation = [rect.x - 0, rect.y + 38];
      }
  }

  async didSelectDropdownOption() {
    if (this.editingIndex !== null && this.editingDropdownType === 'User') {
      // @ts-ignore
      this.selectedComponentDetailsSummarySections[this.editingIndex].Response = this.userDropdownModel[0].value;

      // @ts-ignore
      if (this.editingPreviousValue !== this.selectedComponentDetailsSummarySections[this.editingIndex].Response) {
        this.$emit('set-saving-details-banner-id', 'Updating item');
        // @ts-ignore
        this.updateComponentTextValue(
          this.selectedComponentDetailsSummarySections[this.editingIndex],
          this.selectedComponentDetailsSummarySections[this.editingIndex].Response,
          'VALUE',
        );
      }
      this.editingIndex = null;
      this.userDropdownModel = [];
      this.editingDropdownType = '';
    } else if (this.editingIndex !== null && this.editingDropdownType === 'Core Status') {
      if (this.componentStatus[0].value === 'Waiting On') {
        this.prepareAction(this.activeComponent.ID);
      }
      // @ts-ignore
      const localActiveColumn = JSON.parse(JSON.stringify(this.activeColumns[this.editingIndex]));
      localActiveColumn.Response = {
        Status: this.componentStatus[0].value,
        Payload: {},
      };

      // @ts-ignore
      if (this.editingPreviousValue !== localActiveColumn.Response) {
        this.$emit('set-saving-details-banner-id', 'Updating item');
        // @ts-ignore
        await this.updateComponentTextValue(localActiveColumn, localActiveColumn.Response, 'VALUE');
        this.$emit('add-data-to-gantt-chart');
        this.$emit('remove-saving-details-banner-id', 5000, 'Status updated');
      }
      this.editingIndex = null;
      this.userDropdownModel = [];
      this.editingDropdownType = '';
    }

    this.dropdownLocation = [];
  }

  hideDropdown() {
    this.dropdownLocation = [];
  }

  async datepickerDidUpdateStartDate() {
    if (this.startDateModal === '' || this.ignoreStartDatePicker) {
      this.ignoreStartDatePicker = false;
      return;
    }

    const startDateObj = new Date(this.startDateModal);
    const dueDateObj = new Date(this.dueDateModal);
    startDateObj.setDate(startDateObj.getDate() + 1);
    dueDateObj.setDate(dueDateObj.getDate());

    if (dueDateObj <= startDateObj) {
      this.ignoreStartDatePicker = true;
      this.setStartDateRange();

    // @ts-ignore
    } else if (this.getStartDateRange() !== '' && (this.getStartDateRange()).getTime() !== startDateObj.getTime()) {
      for (let x = 0; x < this.activeColumns.length; x++) {
        if (this.activeComponent.Columns[x].ColumnType === 'START_DATE') {
          // @ts-ignore
          this.editingIndex = x;
        }
      }
      // @ts-ignore
        const localActiveColumn = JSON.parse(JSON.stringify(this.activeColumns[this.editingIndex]));
        localActiveColumn.Response = this.startDateModal;

        // @ts-ignore
        this.$emit('set-saving-details-banner-id', 'Updating item');
        // @ts-ignore
        await this.updateComponentTextValue(localActiveColumn, localActiveColumn.Response, 'VALUE');

        this.$emit('add-data-to-gantt-chart');
        this.$emit('remove-saving-details-banner-id', 5000, 'Status updated');
    }
  }

  async datepickerDidUpdateEndDate() {
    if (this.dueDateModal === '' || this.ignoreEndDatePicker) {
      this.ignoreEndDatePicker = false;
      return;
    }
    const startDateObj = new Date(this.startDateModal);
    const dueDateObj = new Date(this.dueDateModal);
    startDateObj.setDate(startDateObj.getDate());
    dueDateObj.setDate(dueDateObj.getDate() + 1);
    if (dueDateObj <= startDateObj) {
      this.ignoreEndDatePicker = true;
      this.setDueDateRange();

      // @ts-ignore
    } else if (this.getDueDateRange() !== '' && (this.getDueDateRange()).getTime() !== dueDateObj.getTime()) {
      for (let x = 0; x < this.activeColumns.length; x++) {
        if (this.activeComponent.Columns[x].ColumnType === 'DUE_DATE') {
          // @ts-ignore
          this.editingIndex = x;
        }
      }

      // @ts-ignore
      const localActiveColumn = JSON.parse(JSON.stringify(this.activeColumns[this.editingIndex]));
      localActiveColumn.Response = this.dueDateModal;

      // @ts-ignore
      this.$emit('set-saving-details-banner-id', 'Updating item');
      // @ts-ignore
      await this.updateComponentTextValue(localActiveColumn, localActiveColumn.Response, 'VALUE');
      this.$emit('add-data-to-gantt-chart');
      this.$emit('remove-saving-details-banner-id', 5000, 'Status updated');
    }
  }

  datepickerDidUpdate(e) {
    if (e.length > 1) {
      this.$emit('update-single-item-date-range', e[0], e[1], this.activeComponent.ID);
    }
  }

  didFocusOnEditRowText(sectionDetails, type) {
    if (type === 'VALUE') {
      this.editingPreviousValue = sectionDetails.Response;
    } else {
      this.editingPreviousValue = sectionDetails.CustomName;
    }
  }

  didLoseFocusOnEditRowText(sectionDetails, index, type) {
    this.$emit('set-saving-details-banner-id', 'Updating item');

    if (type === 'VALUE') {
      for (let x = 0; x < this.selectedComponentDetailsSummarySections.length; x++) {
        if (this.selectedComponentDetailsSummarySections[x].CustomID === sectionDetails.CustomID) {
          if (this.editingPreviousValue !== this.selectedComponentDetailsSummarySections[x].Response) {
            this.updateComponentTextValue(
              sectionDetails, this.selectedComponentDetailsSummarySections[x].Response, type,
            );
            break;
          }
        }
      }
    } else {
      for (let x = 0; x < this.selectedComponentDetailsSummarySections.length; x++) {
        if (this.selectedComponentDetailsSummarySections[x].CustomID === sectionDetails.CustomID) {
          if (this.editingPreviousValue !== this.selectedComponentDetailsSummarySections[x].CustomName) {
            this.updateComponentTextValue(
              sectionDetails, this.selectedComponentDetailsSummarySections[x].CustomName, type,
            );
            break;
          }
        }
      }
    }
    this.editingPreviousValue = null;
  }

  async updateComponentTextValue(sectionDetails, newResponse, type) {
    let localColumn;
    let b;
    if (type === 'VALUE') {
      for (b = 0; b < this.activeColumns.length; b++) {
        if (this.activeColumns[b].CustomID !== sectionDetails.CustomID) {
          continue;
        }
        localColumn = JSON.parse(JSON.stringify(this.activeColumns[b]));
        localColumn.Response = newResponse;
        scheduleModule.updateColumnByIndex({
          column_index: b,
          column: localColumn,
        });
        // eslint-disable-next-line no-await-in-loop
        await scheduleModule.updateScheduleItem({
          item_id: this.activeComponent.ID,
          columns: JSON.stringify(this.activeColumns),
        });
        break;
      }
    } else {
      for (b = 0; b < this.activeColumns.length; b++) {
        if (this.activeColumns[b].CustomID !== sectionDetails.CustomID) {
          continue;
        }
        localColumn = JSON.parse(JSON.stringify(this.activeColumns[b]));
        localColumn.CustomName = newResponse;
        scheduleModule.updateColumnByIndex({
          column_index: b,
          column: localColumn,
        });
        // eslint-disable-next-line no-await-in-loop
        await scheduleModule.updateScheduleItem({
          item_id: this.activeComponent.ID,
          columns: JSON.stringify(this.activeColumns),
        });
        break;
      }
    }

    this.$emit('remove-saving-details-banner-id', 5000, 'Item was updated');
  }

  getStartDateRange() {
    for (let x = 0; x < this.activeComponent.Columns.length; x++) {
      if (this.activeComponent.Columns[x].ColumnType === 'START_DATE') {
        const startDate = this.activeComponent.Columns[x].Response;

        this.componentDateRange = [];
        const startDateObject = new Date(startDate);
        startDateObject.setDate(startDateObject.getDate() + 1);
        return startDateObject;
      }
    }
    return '';
  }

  getDueDateRange() {
    for (let x = 0; x < this.activeComponent.Columns.length; x++) {
      if (this.activeComponent.Columns[x].ColumnType === 'DUE_DATE') {
        const dueDate = this.activeComponent.Columns[x].Response;

        this.componentDateRange = [];
        const dueDateObject = new Date(dueDate);
        dueDateObject.setDate(dueDateObject.getDate() + 1);
        return dueDateObject;
      }
    }
    return '';
  }

  setDescription() {
    for (let x = 0; x < this.activeComponent.Columns.length; x++) {
      if (this.activeComponent.Columns[x].ColumnType === 'DESCRIPTION' && this.activeComponent.Columns[x].CoreField) {
        this.descriptionModel = this.activeComponent.Columns[x].Response;
        return;
      }
    }
  }

  setTitle() {
    for (let x = 0; x < this.activeComponent.Columns.length; x++) {
      if (this.activeComponent.Columns[x].ColumnType === 'TITLE' && this.activeComponent.Columns[x].CoreField) {
        this.itemTitleModel = this.activeComponent.Columns[x].Response;
        return;
      }
    }
  }

  setStartDateRange() {
    for (let x = 0; x < this.activeComponent.Columns.length; x++) {
      if (this.activeComponent.Columns[x].ColumnType === 'START_DATE') {
        const startDate = this.activeComponent.Columns[x].Response;
        this.componentDateRange = [];
        const startDateObject = new Date(startDate);
        startDateObject.setDate(startDateObject.getDate() + 1);
        this.startDateModal = startDateObject;
        return;
      }
    }
  }

  setDueDateRange() {
    for (let x = 0; x < this.activeComponent.Columns.length; x++) {
      if (this.activeComponent.Columns[x].ColumnType === 'DUE_DATE') {
        const dueDate = this.activeComponent.Columns[x].Response;

        this.componentDateRange = [];
        const dueDateObject = new Date(dueDate);
        dueDateObject.setDate(dueDateObject.getDate() + 1);
        this.dueDateModal = dueDateObject;
        return;
      }
    }
  }

  async created() {
    scheduleModule.setSelectedComponentTabs();
    this.setupSelectedComponentDetailsSummarySections();
    this.setComponentDateRange();
    this.setStartDateRange();
    this.setDueDateRange();
    this.setTitle();
    this.setDescription();
  }

  fileMoreIconSelected(s3URL) {
    if (this.showDeleteFileOption === s3URL) {
      this.showDeleteFileOption = '';
    } else {
      this.showDeleteFileOption = s3URL;
    }
  }

  async deleteComponentOptionSelected() {
    this.$emit('set-saving-details-banner-id', 'Deleting item');
    await scheduleModule.deleteItem({
      item_id: this.activeComponent.ID,
    });

    scheduleModule.setActiveComponent(null);
    this.$emit('add-data-to-gantt-chart');
    this.$emit('remove-saving-details-banner-id', 5000, 'Item deleted');
  }

  confirmDeleteFile(file) {
    scheduleModule.setConfirmDeleteFile(file);
  }

  getFullNameByEmail(email) {
    return getNameByEmail(email);
  }

  expandFile(attachedFile) {
    scheduleModule.setExpandedFile(attachedFile);
  }

  selectedTabIDIsFile(selectedTabID) {
    for (let x = 0; x < this.selectedComponentTabs.length; x++) {
      if (
        this.selectedComponentTabs[x].TabID === selectedTabID
        && this.selectedComponentTabs[x].ColumnType === 'FILE'
      ) {
        return true;
      }
    }
    return false;
  }

  get selectedTabID() {
    return scheduleModule.selectedTabID;
  }

    get enabledWells() {
        return assetsModule.enabledWells;
    }

  get availableUsers() {
    return accountModule.reassignmentList.map((i) => ({
    // @ts-ignore
    text: i.name,
    value: i.email,
    }));
  }

  setupSelectedComponentDetailsSummarySections() {
    const sections = [];
    if (this.activeComponent == null) {
      return sections;
    }
    // @ts-ignore
    for (let x = 0; x < this.activeColumns.length; x++) {
      // @ts-ignore
      if (this.activeColumns[x].ColumnType !== 'FILE'
      // @ts-ignore
      && this.activeColumns[x].ColumnType !== 'PROCEDURE'
      // @ts-ignore
      && this.activeColumns[x].ColumnType !== 'START_DATE'
      // @ts-ignore
      && this.activeColumns[x].ColumnType !== 'DUE_DATE'
      // @ts-ignore
      && this.activeColumns[x].ColumnType !== 'TASQ_DATE_RANGE'
      // @ts-ignore
      && this.activeColumns[x].ColumnType !== 'TITLE'
      // @ts-ignore
      && this.activeColumns[x].ColumnType !== 'DESCRIPTION'
      // @ts-ignore
      && this.activeColumns[x].ColumnType !== 'STATUS'
      // @ts-ignore
      && this.activeColumns[x].ColumnType !== 'CHECKLIST') {
        // @ts-ignore
        sections.push(JSON.parse(JSON.stringify(this.activeColumns[x])));
      } else if (this.activeColumns[x].ColumnType === 'STATUS' && this.activeColumns[x].CoreField) {
        this.componentStatus = [{
         text: 'Scheduled', value: 'Scheduled', color: '#A775FF', disabled: false,
        }];
        for (let z = 0; z < this.statusOptions.length; z++) {
          let responseVal = '';
          if (this.activeColumns[x].Response.constructor === Object) {
            responseVal = this.activeColumns[x].Response.Status;
          } else {
            responseVal = this.statusOptions[z];
          }
          if (this.statusOptions[z].value === responseVal) {
            this.componentStatus = [this.statusOptions[z]];
          }
        }
      }
    }

    this.selectedComponentDetailsSummarySections = JSON.parse(JSON.stringify(sections));
  }

  get selectedComponentDetailsSections() {
    const sections = [];
    if (this.activeComponent == null) {
      return sections;
    }
    // @ts-ignore
    for (let x = 0; x < this.activeColumns.length; x++) {
      // @ts-ignore
      if (this.activeColumns[x].ColumnType === 'CHECKLIST') {
          // @ts-ignore
          sections.push(JSON.parse(JSON.stringify(this.activeColumns[x])));
      }
      // @ts-ignore
      if (this.activeColumns[x].ColumnType === 'DESCRIPTION') {
          // @ts-ignore
          sections.splice(0, 0, JSON.parse(JSON.stringify(this.activeColumns[x])));
      }
    }
    return sections;
  }

  get selectedComponentTabs() {
    return scheduleModule.activeComponentTabs;
  }

  get activeComponent() {
    return scheduleModule.activeComponent;
  }

  get activeColumns() {
    return scheduleModule.activeComponentResponseColumns;
  }

  closeActiveItem() {
    scheduleModule.setActiveComponent(null);
  }

  getAttachedFiles() {
    for (let x = 0; x < this.activeColumns.length; x++) {
      if (this.activeColumns[x].ColumnType === 'FILE') {
        if (this.activeColumns[x].Response === null || this.activeColumns[x].Response === undefined) {
          return [];
        }
        return this.activeColumns[x].Response;
      }
    }
    return [];
  }

  setComponentDateRange() {
    for (let x = 0; x < this.activeComponent.Columns.length; x++) {
      if (this.activeComponent.Columns[x].ColumnType === 'TASQ_DATE_RANGE') {
        const startDate = this.activeComponent.Columns[x].Response[0];
        const endDate = this.activeComponent.Columns[x].Response[1];

        this.componentDateRange = [];
        const startDateObject = new Date(startDate);
        const endDateObject = new Date(endDate);
        startDateObject.setDate(startDateObject.getDate() + 1);
        endDateObject.setDate(endDateObject.getDate() + 1);
        this.componentDateRange.push(startDateObject);
        this.componentDateRange.push(endDateObject);
        return;
      }
    }
  }

  getComponentStartDateString() {
    for (let x = 0; x < this.activeComponent.Columns.length; x++) {
      if (this.activeComponent.Columns[x].ColumnType === 'START_DATE') {
        const startDate = this.activeComponent.Columns[x].Response;
        const year = startDate.split('-')[0];
        const month = startDate.split('-')[1];
        const day = startDate.split('-')[2];
        return `${this.months[month - 1]} ${day.toString()}, ${year}`;
      }
    }
  }

  getComponentEndDateString() {
    for (let x = 0; x < this.activeComponent.Columns.length; x++) {
      if (this.activeComponent.Columns[x].ColumnType === 'DUE_DATE') {
        const endDate = this.activeComponent.Columns[x].Response;
        const year = endDate.split('-')[0];
        const month = endDate.split('-')[1];
        const day = endDate.split('-')[2];
        return `${this.months[month - 1]} ${day.toString()}, ${year}`;
      }
    }
    return 'Due Date';
  }

  async setSelectedComponentTab(selectedTabID) {
    scheduleModule.setSelectedTabID(selectedTabID);
    await sleep(400);
    this.setupDragAndDropSection();
  }

  setupNewLayerDropArea() {
    if (this.storedZipFile != null) {
      this.addNewFile();
    } else {
      // @ts-ignore
        document.getElementById('afterShapefileUploadID').style.display = 'none';
        // @ts-ignore
        document.getElementById('defaultShapefileUploadAreaID').style.display = 'block';
        // @ts-ignore
        document.getElementById('fileDragName').value = '';
        // @ts-ignore
        document.getElementById('fileDragSize').value = '';
        // @ts-ignore
        document.getElementById('fileDragType').value = '';
    }
  }

  onNewFileInputChange() {
    const inputElement = document.getElementById('new_add_shapefile_input') !;
    // @ts-ignore
    const fileList = inputElement.files;
    [this.storedZipFile] = fileList;
    this.setupNewLayerDropArea();
  }

  onFileDrop(e) {
    // @ts-ignore
    this.className = '';
    e.preventDefault();
    // @ts-ignore
    [this.storedZipFile] = e.dataTransfer.files;
    this.setupNewLayerDropArea();
  }

  onFileDragLeave() {
    // @ts-ignore
    this.className = '';
    return false;
  }

  onFileDragEnd() {
    // @ts-ignore
    this.className = '';
    return false;
  }

  onFileDragExit() {
    // @ts-ignore
    this.className = '';
    return false;
  }

  onFileDragOver() {
    // @ts-ignore
    this.className = 'hover';
    return false;
  }

  setupDragAndDropSection() {
    sleep(500).then(() => {
      const holder = document.getElementById('holder');
      // @ts-ignore
      holder.ondragover = this.onFileDragOver;
      // @ts-ignore
      holder.ondragexit = this.onFileDragExit;
      // @ts-ignore
      holder.ondragend = this.onFileDragEnd;
      // @ts-ignore
      holder.ondragleave = this.onFileDragLeave;
      // @ts-ignore
      holder.ondrop = this.onFileDrop;

      const newInputElement = document.getElementById('new_add_shapefile_input') !;
      newInputElement.onchange = this.onNewFileInputChange;
    });
  }

  processScheduledItemFile(file) {
    const extension = file.name.split('.').pop();
    const operator = getConfigEnv('OPERATOR_LOWERCASED');
    this.$emit('set-saving-details-banner-id', `Uploading "${file.name}"`);
    AWS.config.update({
    region: SCHEDULE_BUCKET_REGION,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IDENTITY_POOL_ID,
    }),
    });

    const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: {
      Bucket: SCHEDULE_BUCKET_NAME,
    },
    });

  const key = `${operator}/${accountModule.user.email.toLowerCase()}/${file.name}`;
    if (extension === 'PNG' || extension === 'png' || extension === 'jpeg' || extension === 'jpg') {
      s3.upload({
        Key: key,
        Bucket: SCHEDULE_BUCKET_NAME,
        Body: file,
        ACL: 'public-read',
      },
      (err) => {
        if (err) {
          console.error(err, 'there was an error uploading your file');
        } else {
          const s3URL = `https://${SCHEDULE_BUCKET_NAME}.s3.amazonaws.com/${key}`;
          this.addFileToItem(s3URL, extension, file.name);
        }
      });
    } else if (extension === 'pdf') {
      s3.upload({
        Key: key,
        Bucket: SCHEDULE_BUCKET_NAME,
        Body: file,
        ContentDisposition: 'inline',
        ACL: 'public-read',
        ContentType: 'application/pdf',
      },
      (err) => {
        if (err) {
          console.error(err, 'there was an error uploading your file');
        } else {
          const s3URL = `https://${SCHEDULE_BUCKET_NAME}.s3.amazonaws.com/${key}`;
          this.addFileToItem(s3URL, extension, file.name);
        }
      });
    }
  }

  async addFileToItem(s3URL, extension, fileName) {
    for (let b = 0; b < this.activeColumns.length; b++) {
      if (this.activeColumns[b].ColumnType !== 'FILE') {
        continue;
      }
      const localColumn = JSON.parse(JSON.stringify(this.activeColumns[b]));
      if (localColumn.Response == null) {
        localColumn.Response = [];
      }

      localColumn.Response.push({
        S3Url: s3URL,
        FileName: fileName,
        Username: accountModule.user.email.toLowerCase(),
        Extension: extension,
        DateCreated: DateTime.utc().toFormat("y-MM-dd'T'HH:mm:ss"),
        DateModified: DateTime.utc().toFormat("y-MM-dd'T'HH:mm:ss"),

      });
      scheduleModule.updateColumnByIndex({
        column_index: b,
        column: localColumn,
      });
      // eslint-disable-next-line no-await-in-loop
      await scheduleModule.updateScheduleItem({
        item_id: this.activeComponent.ID,
        columns: JSON.stringify(this.activeColumns),
      });
    }
    this.filesKeyCount += 1;
    this.noFilesKeyCount += 1;
    this.refreshFilesKey += 1;
    this.$emit('remove-saving-details-banner-id', 5000, `Uploaded "${fileName}"`);
    this.$emit('update-component-details');
  }

  readfiles(file) {
    if (file) {
      this.processScheduledItemFile(file);
    }
  }

  addNewFile() {
    this.dataLoading = true;
    if (this.storedZipFile != null) {
      this.readfiles(this.storedZipFile);
    }
  }

  async didSelectChecklistItem(item, index) {
    for (let x = 0; x < this.activeColumns.length; x++) {
      if (this.activeColumns[x].ID === item.ID && this.activeColumns[x].CustomName === item.CustomName) {
        scheduleModule.updateActiveColumnsChecklist({ single_index: index, single_x: x });

        const localColumn = JSON.parse(JSON.stringify(this.activeColumns[x]));
        scheduleModule.updateColumnByIndex({
          column_index: x,
          column: localColumn,
        });
      }
    }

    this.$emit('update-component-details');
    await scheduleModule.updateScheduleItem({
      item_id: item.ItemID,
      columns: JSON.stringify(this.activeColumns),
    });
  }

  getFullComponentDetailsByID(id) {
    // @ts-ignore
    for (let x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
      // @ts-ignore
      for (let y = 0; y < workspaceModule.activeTable.Groups[x].Items.length; y++) {
        // @ts-ignore
        if (id === workspaceModule.activeTable.Groups[x].Items[y].ID) {
          // @ts-ignore
          return workspaceModule.activeTable.Groups[x].Items[y];
        }
      }
    }
  }

  showComponentDetails(component) {
    const detailedComponent = this.getFullComponentDetailsByID(component.component_id);
    if (detailedComponent != null) {
      scheduleModule.setActiveComponent(detailedComponent);
      for (let x = 0; x < detailedComponent.Columns.length; x++) {
        detailedComponent.Columns[x].ItemID = detailedComponent.ID;
      }
      scheduleModule.setActiveComponentResponseColumns(detailedComponent.Columns);
    }
  }

  getColumnTypeIcon(columnType) {
    if (columnType === 'WELL') {
      return 'task';
    } if (columnType === 'USER') {
      return 'person';
    } if (columnType === 'VENDOR') {
      return 'engineering';
    } if (columnType === 'CHECKLIST') {
      return 'checklist';
    } if (columnType === 'FILE') {
      return 'picture_as_pdf';
    } if (columnType === 'TESTERS') {
      return 'quiz';
    } if (columnType === 'TASQ_DATE_RANGE') {
      return 'date_range';
    } if (columnType === 'COST') {
      return 'attach_money';
    } if (columnType === 'TAGS') {
      return 'tag';
    } if (columnType === 'TEXT') {
      return 'format_size';
    } if (columnType === 'DATE') {
      return 'calendar';
    } if (columnType === 'PROCEDURE') {
      return 'task';
    } if (columnType === 'EMAIL') {
      return 'email';
    } if (columnType === 'PHONE_NUMBER') {
      return 'phone';
    }
      return 'task';
  }

    @Watch('kanbanTasq')
  async onkanbanTasqChange(id: string) {
    if (id) {
      this.showModal = true;
    } else {
      this.$dialog.hide('actionModal');
      this.showModal = false;
    }
  }
}

